import React, {Component} from "react";
import Ultramat23 from "./Ultramat23";
import Ultramat6 from "./Ultramat6";
import Lds6 from "./Lds6";
import Fidamat6 from "./Fidamat6";
import Durag from "./Durag";
import {connect} from 'react-redux';
import Ultramat23Cabinet from "./Ultramat23Cabinet";
import Ultramat6Cabinet from "./Ultramat6Cabinet";
import Lds6Cabinet from "./Lds6Cabinet";
import FidamatCabinet from "./Fidamat6Cabinet";
import DuragCabinet from "./DuragCabinet";

class CabinetConfiguration extends Component {



    state= {
            boxText: null,
            boxDisplay: "none",
            caseDisplay: "block",
            boxSize: "null"
        }



    handleBox1 = () => {
        this.setState ({boxText:'case1',boxDisplay:"block"})
    }
    handleBox2 = () => {
        this.setState ({boxText:'case2',boxDisplay:"block"})

    }
    click3 = () => {
        this.setState({boxText:null})

    }
    handleText = (param) => {
        this.setState(
            {boxText:param,
            caseDisplay:"none",
                boxSize: "380px"
            })


    }




     render() {
        const{title1,title2,box} = this.props
         const myStyle = {
             marginLeft:"10%",
             backgroundColor: "black",


             overflowX:"hidden",
             display:this.props.displ
         }

         const myStyle2 ={
             display: this.props.displayconfigur,
             marginLeft:"10%",


         }
         const myStyle3 ={
             display: this.props.displayreturn,
             marginLeft:"10%"


         }
        return (
            <>
                <div className="row " style={{height: "100%"}}>
                    <div className="col-12" style={{height: "100%",position:"relative"}} >
                        <div className="row no-gutter"  style={{marginTop: "5%",marginLeft:"10%"}}>
                            <div className="col-12" style={{display:`${this.props.displaybox}`}}>
                            <h5>
                                Select a regulatory requirement to configure your control cabinet

                            </h5>
                        </div>
                        </div>
                        <div className="  mt-2 " style={{marginLeft:"10%",display:`${this.props.displaybox}`,height: "15%"}}>

                            <div style={{display:this.state.caseDisplay}}  className="w-50 mr-2  box "  onClick={this.props.changeTextBox2}>
                                <div className="w-100 h-100 pl-2  "
                                     style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                                    <div  className="mr-auto">
                                    <h5>
                                        {title1.title}
                                    </h5>
                                    </div>
                                    <div className="h-100 pb-1 d-flex  align-items-end ">
                                    <svg  viewBox="0 0 16 16" className="bi bi-chevron-right arrow"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </div>

                                </div>

                            </div>

                            <div style={{display:this.state.caseDisplay}} className=" w-50 text-white box"
                                 onClick={this.props.changeTextBox3}>
                                <div className="w-100 h-100 pl-2 " style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                                 <div className="mr-auto">
                                    <h5>
                                        {title2.title}
                                    </h5>
                                 </div>
                                    <div className="h-100 pb-1 d-flex  align-items-end ">
                                    <svg  viewBox="0 0 16 16" className="bi bi-chevron-right arrow"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className={this.props.cabconfig4} style={myStyle}>

                            {
                                {
                                    'case1': title1.text,
                                    'case2': title2.text,
                                    'ULTRAMAT 23' : <Ultramat23/>,
                                    'ULTRAMAT 6' : <Ultramat6/>,
                                    'LDS6' : <Lds6/>,
                                    'FIDAMAT 6' : <Fidamat6/>,
                                    'DURAG HM- 1400 TRX' : <Durag/>,
                                    'ULTRAMAT 231':<Ultramat23Cabinet/>,
                                    'ULTRAMAT 61':<Ultramat6Cabinet/>,
                                    'LDS61' : <Lds6Cabinet/>,
                                    'FIDAMAT 61':<FidamatCabinet/>,
                                    'DURAG HM- 1400 TRX1' : <DuragCabinet/>

                                } [this.props.boxt  ]
                            }


                        </div>
                        <div className="w-100" style={{display:"flex", justifyContent:`${this.props.justifycon}`}}>
                            <div onClick={this.props.previousstate}  className=" text-white  configbox2  " style={myStyle3} >
                               <div className="w-100 h-100 d-flex align-items-center justify-content-center " >

                                   <div className="mr-3">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                            fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                           <path fill-rule="evenodd"
                                                 d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                       </svg>

                                   </div>
                                   <div>
                                      return
                                   </div>

                               </div>


                            </div>
                            <div onClick={this.props.showConfiguration } className=" text-white bg-warning configbox  " style={myStyle2} >
                            <div className="h-100 d-flex align-items-center justify-content-center " >

                                <div>
                                    configure now
                                </div>
                                <div className="ml-3">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right arrow"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </div>


                            </div>


                        </div>
                    </div>
                    </div>

                </div>
            </>

        )
     }
}
const mapStateToprops = state => {
    return {
        boxt : state.boxText,
        displ : state.display,
        displaybox:state.displayBox,
        cabconfig4: state.cabConfig4,
        displayreturn:state.displayReturn,
        justifycon: state.justifyCon,
        displayconfigur: state.displayConfigur,



    }
}
const mapDispatchToProps = dispatch => {
    return  {
        changeTextBox2: () => dispatch({
            type: 'CHANGEBOXTEXT2' ,
            payload : 'case1'

        }),
        changeTextBox3: () => dispatch({
            type: 'CHANGEBOXTEXT3' ,
            payload : 'case2'
        }),
        previousstate: () => dispatch({
            type: 'PREVIOUSSTATE',

        }),
        showConfiguration : () => dispatch({
            type: "SHOWCONFIGURATION"
        })

    }
}



export default connect(mapStateToprops,mapDispatchToProps)(CabinetConfiguration) ;