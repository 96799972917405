import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_FIDAMAT_6_675x267px.png";

const Fidamat6 = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        Determines the total content
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="FIDAMAT6" src={UltramtBild}/>
                </div>


                <p>
                    The FIDAMAT 6 carries out substance-specific measurements, not component-specific measurements. It records the total of all hydrocarbons in a sample gas – with different weighting of the hydrocarbon molecules.
                </p>
                <h6>
                    Applications
                </h6>

                <ul>
                    <li>Environmental protection</li>
                    <li> Wastewater (in combination with a stripping device, detection of hydrocarbon content of liquids)</li>
                    <li>Monitoring of air in fruit stores, greenhouses, fermenting cellars and warehouses</li>
                    <li> TLV (Threshold Limit Value) monitoring at workplaces</li>
                    <li>Quality monitoring</li>
                    <li>Process exhaust monitoring</li>
                    <li>Ultra-pure gas measurement in media such as O<sub>2</sub>, CO<sub>2</sub>, inert gases and cold sample gases</li>
                    <li>Measurement of corrosive and condensing gases</li>
                    <li>Process optimization </li>
                </ul>


                <h6>
                    Sectors
                </h6>


                <ul>
                    <li>chemical plants</li>
                    <li>Gas manufacturers (ultra-pure gas monitoring)</li>
                    <li>Research and development </li>
                    <li>Cement industry (measurement of emissions)</li>
                    <li>Paint shops and dry-cleaning systems</li>
                    <li>Refineries (tank farms, wastewater)</li>
                    <li>Drying systems</li>
                    <li>Solvent recovery systems </li>
                    <li>Pharmaceutical industry</li>
                    <li>Automotive industry (engine development, engine and transmission
                        development and certification)
                    </li>

                </ul>


                <h6>Advantages</h6>


                <ul>
                    <li>In the presence of up to 100 % H<sub>2</sub>O vapor</li>
                    <li>In ultra-pure gas applications</li>
                    <li>With high-boiling components (up to 200 °C)</li>
                    <li>In the presence of corrosive gases (with preliminary filter)</li>
                    <li>Extremely low cross-sensitivity to interfering gases</li>
                    <li>Low consumption of combustion air</li>
                    <li>Low influence of oxygen on measured value</li>
                </ul>

            </div>

        </>
    )
}

export default Fidamat6;