
import TextSolid1 from "./TextSolid1";
import Text2SolidBiomass from "./Text2SolidBiomass";
import Text1NaturalGas from "./Text1NaturalGas";
import Text2NaturalGas from "./Text2NaturalGas"
import Test1Coral from "./Test1Coral";
import TestCoral2 from "./TestCoral2";
import Text1Biomass from "./Text1Biomass";
import Text2Biomass from "./Text2Biomass";
import Text1Coincineration from "./Text1Coincineration";
import Text2Coincineration from "./Text2Coincineration";
import Text1Clement from "./Text1Clement";
import Text2Clement from "./Text2Clement";
import React from "react";
import Schrank1Gerät from "./Schrank1Gerät";
import Schrank2Gerät from "./Schrank2Gerät";
import Schrank4Gerät from "./Schrank4Gerät";





export const data = [
    {
        id:'solidbiomass',
        title:'SOLID BIOMASS FIRED MCP',

        subTitle1:{
            title: '44th BImSchV and MCPD Compliance',
            text: <TextSolid1/>
        },
        subTitle2:{
            title:'MCPD compliance',
            text:<Text2SolidBiomass/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle:"Our unbeatable all-rounder"
            }
        ],
        gerät : <Schrank1Gerät/>

    },
    {
        id:'naturalgas',
        title:'NATURAL GAS FIRED TURBINE',
        subTitle1:{
            title:'44th BImSchV and MCPD Compliance',
            text: <Text1NaturalGas/>
        },
        subTitle2:{
            title:'MCPD compliance',
            text:<Text2NaturalGas/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle:"Our unbeatable all-rounder"
            },
            {
                title: "ULTRAMAT 6",
                subtitle: "The expert for all infrared-active gases"
            }
        ],
        gerät : <Schrank2Gerät/>


    },
    {
        id:'coalfiredlcp',
        title:'COAL FIRED LCP',
        subTitle1:{
            title:'13th BImSchV and IED',
            text: <Test1Coral/>
        },
        subTitle2:{
            title:'IED compliance',
            text:<TestCoral2/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle: "Our unbeatable all-rounder"
            }
        ],
        gerät : <Schrank1Gerät/>


    },
    {
        id:'biomassfiredlcp',
        title:'BIOMASS FIRED LCP',
        subTitle1:{
            title:'13th BImSchV and IED compliance',
            text: <Text1Biomass/>
        },
        subTitle2:{
            title:'IED compliance',
            text:<Text2Biomass/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle: "Our unbeatable all-rounder"
            }
        ],
        gerät : <Schrank1Gerät/>


    },
    {
        id:'coincinerationofsolids',
        title:'CO-INCINERATION OF SOLIDS',
        subTitle1:{
            title:'17th BImSchV and IED compliance',
            text: <Text1Coincineration/>
        },
        subTitle2:{
            title:'IED compliance',
            text:<Text2Coincineration/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle: "Our unbeatable all-rounder"
            },
            {
                title: "ULTRAMAT 6",
                subtitle: "The expert for all infrared-active gases"
            },
            {
                title: "LDS6",
                subtitle: "Laser measurement directly in the process "
            },
            {
                title: "FIDAMAT 6",
                subtitle: "Our specialist for hydrocarbons"
            },
            {
            title: "DURAG HM- 1400 TRX",
            subtitle: "The mercury determination analyser from DURAG"
            }

        ],
        gerät : <Schrank4Gerät/>

    },
    {
        id:'cementplant',
        title:'CEMENT PLANT',
        subTitle1:{
            title:'17th BImSchV and IED compliance',
            text: <Text1Clement/>
        },
        subTitle2:{
            title:'IED compliance',
            text:<Text2Clement/>
        },
        config:[
            {
                title: "ULTRAMAT 23",
                subtitle: "Our unbeatable all-rounder"
            },
            {
                title: "ULTRAMAT 6",
                subtitle: "The expert for all infrared-active gases"
            },
            {
                title: "LDS6",
                subtitle: "Laser measurement directly in the process "
            },
            {
                title: "FIDAMAT 6",
                subtitle: "Our specialist for hydrocarbons"
            },
            {
                title: "DURAG HM- 1400 TRX",
                subtitle: "The mercury determination analyser from DURAG"
            }

        ],
        gerät : <Schrank4Gerät/>

    }

]