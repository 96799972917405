import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_LDS6_1_675x267px.png";
import UltramtBild2 from  "./media/Touch-App-Geraeteabbildung_Ultramat_23_2_675x267px.png";
import Caroussel from "./Caroussel";
import DemoCarousel from "./DemoCaroussel";
import co from "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import Lds6BildSlider from "./media/Touch-App-Geraeteabbildung_LDS6_2_675x267px.png";
import NH3 from "./media/touchapp-chemical-icon-nh3-64x64px.png";
import HCI from "./media/touchapp-chemical-icon-hci-64x64px.png";
import HF from "./media/touchapp-chemical-icon-hf-64x64px.png";
import H2o from "./media/touchapp-chemical-icon-h2o-64x64px.png";


const Lds6Cabinet = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        Signal processing of up to three measuring points

                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    {/* <img alt="Ultramat" src={UltramtBild}/> */}
                    <DemoCarousel image1={UltramtBild} image2={Lds6BildSlider }/>
                </div>
                <div className="d-flex justify-content-around mb-3">

                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt= "oxygen" src={o2}  style={{width:"100%"}}/>
                        <span>oxygen</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt="ammonia" src={NH3}  style={{width:"100%"}}/>
                        <span>ammonia</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt = "hydochloric" src={HCI}  style={{width:"100%"}}/>
                        <span>hydochloric acid</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt = "hydrogen fluoride" src={HF}  style={{width:"100%"}}/>
                        <span>hydrogen fluoride</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img alt = "carbon oxides" style={{width:"100%"}} src={co} />
                        <span>carbon oxides</span>

                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img  alt = "water" style={{width:"100%"}} src={H2o} />
                        <span>water</span>

                    </div>



                </div>


                <h6>
                    how it works
                </h6>

                <p>
                    LDS 6 is a gas analyzer that employs single-line molecular absorption spectroscopy. One or two signals from up to three measuring points are processed simultaneously by the central analyzer unit.
                    The in-situ cross-duct sensors at each measuring point can be separated
                    up to 700 m from the central unit by using fiber-optic cables.

                </p>


                <h6>
                    Measuring principle of infrared measurement:
                </h6>


                <ul>
                    <li>A diode laser emits a beam of near-infrared light,   which passes through the process gas and is detected by a receiver unit. </li>
                    <li>The wavelength of the laser diode output is tuned to a gas-specific absorption line. </li>
                    <li>The laser continuously scans this single absorption line with a very high spectral resolution. </li>
                    <li>The result is a fully resolved single molecular line which is analyzed in terms of absorption strength and line shape. </li>
                    <li>The influence of cross-sensitivities on the measurement is negligible,
                        since the quasi-monochromatic laser light is absorbed very selectively by only one specific molecular line in the scanned spectral range.</li>

                </ul>
                <div className="mb-3">
                <h6>
                    Technical specifications
                </h6>
                </div>
                <table className="table text-white table-bordered">

                    <tbody>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Analytical performance
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Measuring range


                        </td>
                        <td>
                            Depending on sample gas component

                        </td>
                    </tr>


                    <tr class="table-dark">
                        <td colSpan="2"><h6>
                            General information
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Concentration units
                        </td>
                        <td>
                            ppmv, Vol%, mg/Nm

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Display
                        </td>
                        <td>
                            Digital concentration display (5 digits with floating decimal point)

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Laser protection class

                        </td>
                        <td>
                            class 1, safe to the eye

                        </td>
                    </tr>
                    <tr>
                        <td>
                            certificates

                        </td>
                        <td>
                            CE marking, TÜV, MCERTS

                        </td>
                    </tr>
                    <tr class="table-dark">
                        <td colSpan="2"><h6>
                            Design, enclosure
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Degree of protection
                        </td>
                        <td>
                            IP20 according to EN 60529

                        </td>
                    </tr>

                    <tr class="table-dark">
                        <td colspan="2"> <h6>
                            Time response
                        </h6> </td>
                    </tr>
                    <tr>
                        <td>
                             Warm-up time at 20 <sup>o</sup>C ambient temperature<br/>
                        </td>
                        <td>
                           Approx 15 min

                        </td>
                    </tr>
                    <tr>
                        <td>
                            response time
                        </td>
                        <td>
                            Min. of 1 s, depending on application

                        </td>
                    </tr>
                    <tr>
                        <td>
                            integration time
                        </td>
                        <td>
                            1 ... 100 s, adjustable

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                          Electrical inputs and outputs
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                           Number of measurement channels
                        </td>
                        <td>
                            1 ... 3, optional

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Analog output
                        </td>
                        <td>
                           2 per channel, designed for <br/> 4 ...20 ma, 50 &#8486;

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Digital outputs

                        </td>
                        <td>
                            6 per channel, with changeover contacts, configurable, 24V/AC/DC/1 A, floating

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Digital inputs

                        </td>
                        <td>
                           6 per channel, designed for 24 V, floating,
                            configurable

                        </td>
                    </tr>
                    <tr>
                        <td>
                          Communication interface

                        </td>
                        <td>
                           Ethernet 10BaseT (RJ -45)

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                           Climatic conditions
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                           Temperature range
                        </td>
                        <td>
                           5 ... 45 <sup>o</sup>C C during operation

                        </td>
                    </tr>
                    </tbody>
                </table>


            </div>

        </>
    )
}

export default Lds6Cabinet;