import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_DURAG_1400TRX_675x267px.png";
import UltramtBild2 from  "./media/Touch-App-Geraeteabbildung_Ultramat_23_2_675x267px.png";
import Caroussel from "./Caroussel";
import DemoCarousel from "./DemoCaroussel";
import co from "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import Ultramat6BildSlider from "./media/Touch-App-Geraeteabbildung_ULTRAMAT_6_2_675x267px.png";
import NH3 from "./media/touchapp-chemical-icon-nh3-64x64px.png";
import H2o from "./media/touchapp-chemical-icon-h2o-64x64px.png";
import Ultramat6Grafik from "./media/touchapp-schaltschraenke-grafik3-de-2000x2000px.png";
import HG from "./media/touchapp-chemical-icon-hg-64x64px.png";

const DuragCabinet = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        Continuously measurement of the mercury vapor in a photometer

                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="Ultramat" src={UltramtBild}/>

                </div>
                <div className="d-flex justify-content-around mb-3">
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img alt="mercury" style={{width:"100%"}} src={HG} />
                        <span>mercury</span>

                    </div>




                </div>

                <h6>
                    how it works
                </h6>

                <p>
                    In the HM 1400 TRX total mercury analyzer the sample gas is converted into mercury vapor by a combination of thermal and chemical treatment. It is then continuously measured in a photometer.

                </p>
                <ul>
                    <li>Gas-filled filter chamber (beam splitter)</li>
                    <li>Double-layer detector with optical coupler</li>
                    <li>Optical filters if necessary</li>

                </ul>


                <h6>
                    Measuring principle
                </h6>




                <ul>
                    <li>The sample gas flow is measured after a sample game cooler at 2 °C.  </li>
                    <li>The concentration is calculated and displayed as "dry flue gas".</li>

                </ul>



            </div>

        </>
    )
}

export default DuragCabinet;