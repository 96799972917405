import React,{Component} from 'react';
import {connect} from 'react-redux';



class ButtonOpencabinet extends Component {

render() {

const {ValueOfCabinet} = this.props
    return (
        <button value={ValueOfCabinet}   className=" w-50  text-white bg-info boxOpencabinet " onClick={(e) => this.props.changeTextBox4(e.target.value)} >


                    open cabinet

        </button>
    )
}
}
const mapStateToprops = state => {
    return {


    }
}

const mapDispatchToProps = dispatch => {
    return  {
        changeTextBox4: ( value) => dispatch({
            type: 'CHANGEBOXTEXT4' ,
            payload : value

        })
    }
}

export default connect(mapStateToprops,mapDispatchToProps)( ButtonOpencabinet);