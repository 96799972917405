import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_FIDAMAT_6_675x267px.png";
import UltramtBild2 from  "./media/Touch-App-Geraeteabbildung_Ultramat_23_2_675x267px.png";
import Caroussel from "./Caroussel";
import DemoCarousel from "./DemoCaroussel";
import co from "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import Ultramat6BildSlider from "./media/Touch-App-Geraeteabbildung_ULTRAMAT_6_2_675x267px.png";
import NH3 from "./media/touchapp-chemical-icon-nh3-64x64px.png";
import H2o from "./media/touchapp-chemical-icon-h2o-64x64px.png";
import Ultramat6Grafik from "./media/touchapp-schaltschraenke-grafik3-de-2000x2000px.png";

const FidamatCabinet = () => {

    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        Measurement of all hydrocarbons with different weighting of the molecules

                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="Ultramat" src={UltramtBild}/>

                </div>


                <h6>
                    how it works
                </h6>

                <p>
                    The FIDAMAT 6 measures the total of all hydrocarbons in the process,
                    but with different weighting of the hydrocarbon molecules. To a first approximation,
                    the display is proportional to the number of C atoms in the respective molecule. However, in practice there are fluctuations. The display deviation for the specific molecule is expressed by the response factor.

                </p>


                <h6>
                    Measuring principle of infrared measurement:
                </h6>




                <ul>
                    <li>The sample gas is supplied to the FIDAMAT 6 through overpressure or drawn in by the built-in diaphragm pump (optionally via a heated line and an additional filter).  </li>
                    <li>It is passed onto the flame ionization detector via an obstruction-proof
                        fused silica restrictor.
                    </li>
                    <li>In the detector, the hydrocarbons in the sample gas are burned in an oxyhydrogen gas flame. Burning partially ionizes the proportion of organically bound hydrocarbons. </li>
                    <li>The released ions are converted into an ionic current by the voltage present between two electrodes, and measured using a highly sensitive amplifier. </li>
                    <li>The current measured is proportional to the quantity of organically bound C atoms of the hydrocarbons in the sample gas.</li>
                    <li>A pressure regulator keeps the combustion gas pressure constant.  </li>
                    <li>The balanced system of pump, capillary tubes, and pressure regulator for combustion air ensures that the sample gas pressure is kept constant. </li>

                </ul>


                <div className="mb-3">
                    <h6>
                        19" rack unit
                    </h6>
                </div>
                <div className="mb-3">
                    <h6>
                        Technical specifications
                    </h6>
                </div>
                <table className="table text-white table-bordered">

                    <tbody>


                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            General information
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Measured ranges
                        </td>
                        <td>
                            4, internally and externally switchable; manual and  autoranging is possible

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Smallest possible measuring spam
                        </td>
                        <td>
                           0...10 vpm

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Largest possible measuring span
                        </td>
                        <td>
                            99,999 vpm<sup>*</sup>)

                        </td>
                    </tr>
                    <tr>
                        <td>
                           Concentration units

                        </td>
                        <td>
                            vpm, C<sub>1</sub>, C<sub>3</sub>, C<sub>6</sub> or mgC/m&#60;up&#62;3&#60;/up&#62;

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Autoranging

                        </td>
                        <td>
                           Hysteresis, selectable

                        </td>
                    </tr>
                    <tr>
                        <td>
                           Measured-value display

                        </td>
                        <td>
                            Digital concentration display (5 digits with floating point)

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Resolution of digital display

                        </td>
                        <td>
                            0,1% of measured value


                        </td>
                    </tr>
                    <tr>
                        <td>
                            Operating position

                        </td>
                        <td>
                          Front wall, vertical


                        </td>
                    </tr>
                    <tr>
                        <td>
                           Conformity

                        </td>
                        <td>
                            CE mark in accordance with EN 50081-1, EN 500082-2


                        </td>
                    </tr>
                    <tr>
                        <td>
                           Oven temperature

                        </td>
                        <td>
                           Adjustable, 100 ... 200<sup>o</sup>C


                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Design, enclosure
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                           Degree of protection
                        </td>
                        <td>
                            IP20 according to EN 60529

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Weight
                        </td>
                        <td>
                            Approx.23 Kg

                        </td>
                    </tr>


                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Dynamic response
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Warm-up period
                        </td>
                        <td>
                            At room temperature, approx. 2 ... 3 h

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Delayed display (T90)
                        </td>
                        <td>
                           2 ... 3 s

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Damping (electrical time constant)
                        </td>
                        <td>
                            0 ... 100 s, configurable

                        </td>
                    </tr>
                    <tr>
                        <td>
                           Dead time  (purging time of the gas path in the unit at 1 l/min)
                        </td>
                        <td>
                           with filter 2 ... 3s

                        </td>
                    </tr>
                    <tr>
                        <td>
                           Time for device-internal signal processing
                        </td>
                        <td>
                            &#60; 1 s

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Drift
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Zero point drift
                        </td>
                        <td>
                            &lt; 0,5%/month of the smallest possible measuring span according to rating plate

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Measured-value drift
                        </td>
                        <td>
                            &lt; 0,5%/week of the current measuring range

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Electrical inputs and outputs
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Analog output
                        </td>
                        <td>
                            0/2/4 ... 20mA, isolated; max. load  750&#8486;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            relay outputs
                        </td>
                        <td>
                            6, with changeover contacts,
                            freely configurable, e.g. for measuring range identification; load: 24 V AC/DC/1 A,
                            potential-free


                        </td>
                    </tr>
                    <tr>
                        <td>
                            Analog inputs
                        </td>
                        <td>
                            2, dimensioned for 0/2/4 ... 20 mA for external pressure sensor and correction of influence of
                            accompanying gas (correction of cross-interference)


                        </td>
                    </tr>
                    <tr>
                        <td>
                            Digital inputs
                        </td>
                        <td>
                            6, designed for 24 V, floating, freely configurable, e.g. for measuring range switchover
                        </td>
                    </tr>
                    <tr>
                        <td>
                            serial interface
                        </td>
                        <td>
                            RS 485

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Options
                        </td>
                        <td>
                            AUTOCAL function each with 8 additional digital inputs and relay outputs, also with PROFIBUS

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Climatic conditions
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Permissible ambient temperature
                        </td>
                        <td>
                            5 ... 45 <sup>o</sup>C in operation

                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </>
    )
}

export default FidamatCabinet;