import React from "react";
import Gerät2 from "./media/Schaltschrank_2_Geraete.png";


const Schrank2Gerät = () => {
    return (
        <>

            <img src={Gerät2} alt="schrank"  width="135%" height="97%"/>


        </>
    )
}

export default React.memo( Schrank2Gerät);