import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class DemoCarousel extends Component {
    render() {
        const {image1,image2} = this.props
        return (
            <Carousel>
                <div>
                    <img alt= "Ultramat" src={image1} />

                </div>
                <div>
                    <img alt= "Ultramat" src={image2} />

                </div>

            </Carousel>
        );
    }
}
export default DemoCarousel;