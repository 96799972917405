import React from "react";
import UltramtBild from "./media/sie-rack-device-persp1-fr-001-39l-ohne-bearbeitet_675x267-freigestellt (002).png";
import UltramtBild2 from  "./media/Touch-App-Geraeteabbildung_Ultramat_23_2_675x267px.png";
import Caroussel from "./Caroussel";
import DemoCarousel from "./DemoCaroussel";
import co from "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import UltramatGrafik1 from "./media/touchapp-schaltschraenke-grafik1-en-2000x2000px.png";
import UltramatGrafik2 from "./media/touchapp-schaltschraenke-grafik2-en-2000x2000px.png"

const Ultramat23Cabinet = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        two measuring principles which work  <br/>
                        selectively

                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    {/* <img alt="Ultramat" src={UltramtBild}/> */}
                    <DemoCarousel image1={UltramtBild} image2={UltramtBild2}/>
                </div>
                <div className="d-flex justify-content-around mb-3">
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img alt="carbon oxides" style={{width:"100%"}} src={co} />
                        <span>carbon oxides</span>

                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon" >
                        <img alt="hydrocarbons" style={{width:"100%"}} src={cnhm}/>
                        <span>hydrocarbons</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon"  >
                        <img alt="sulfur dioxides" src={so2} style={{width:"100%"}}/>
                        <span>sulfur dioxides</span>

                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon ">
                        <img  alt="nitrogen oxides" src={nox}  style={{width:"100%"}}/>
                        <span>nitrogen oxides</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt="sulfur hexafloride" src={sf6}  style={{width:"100%"}}/>
                        <span>sulfur hexafloride</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon"  >
                        <img  alt="hydrogen sulfide" src={h2s}  style={{width:"100%"}}/>
                        <span>hydrogen sulfide</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img  alt="oxygen" src={o2}  style={{width:"100%"}}/>
                        <span>oxygen</span>
                    </div>

                </div>


                <h6>
                    how it works
                </h6>

                <p>
                    The ULTRAMAT 23 uses two independent measuring principles which work selectively: infrared and ultraviolet measurement
                </p>


                <h6>
                    Measuring principle of infrared measurement:
                </h6>
                <div className="d-flex justify-content-center align-items-center" >
                    <img alt="Ultramat" className="Ultramat23Grafik"   src={UltramatGrafik1}/>

                </div>
                <p>The measuring principle of the ULTRAMAT 23 is based on the molecule-specific absorption of bands
                    of infrared radiation, which in turn is based on the "single-beam procedure". </p>


                <ul>
                    <li>A radiation source operating at 600 °C emits infrared radiation, which is then modulated by a chopper</li>
                    <li>The IR radiation passes through the sample chamber, into which sample gas is flowing, and its intensity is
                        attenuated as a function of the concentration of the measured component.</li>
                    <li>The receiver chamber – set up as a two- or three-layer detector – is filled with the component to be measured.</li>
                    <li>The first detector layer primarily absorbs energy from the central sections of the sample gas IR bands. </li>
                    <li>Energy from the peripheral sections of the bands is absorbed by the second and third detector layers. </li>
                    <li>Negative feedback from the upper and lower layers, which are pneumatically connected to each other via the microflow sensor,
                        leads to an overall narrowing of the spectral sensitivity band. </li>
                    <li>The volume of the third layer and, therefore, the absorption of the bands, can be varied using a "slider",
                        thereby increasing the selectivity of each individual measurement. </li>
                    <li>The rotating chopper generates a pulsating flow in the receiver chamber that the microflow sensor converts into an electrical signal. </li>
                    <li>The microflow sensor consists of two nickel-plated grids, which, along with two supplementary resistors, form a Wheatstone bridge. </li>
                    <li>The pulsating flow together with the dense arrangement of the Ni grids causes a change in resistance. </li>
                    <li>This leads to an offset in the bridge which is proportional to the concentration of sample gas. </li>
                </ul>


                <h6>Measuring principle of ultraviolet measurement:</h6>

                <div className="d-flex justify-content-center align-items-center" >
                    <img alt="Ultramat" className="Ultramat23Grafik"  src={UltramatGrafik2}/>

                </div>
                <p>This measuring principle is also based on the molecule-specific absorption of bands of ultraviolet radiation using a double-beam photometer</p>

                <ul>
                    <li>The light source is a solid-state diode (LED) based on semiconductors.</li>
                    <li>To improve the signal evaluation, the light source is operated as a pulsed light source. </li>
                    <li>The ultraviolet radiation first passes through a beam splitter, which generates two identically sized ray bundles (measuring and reference radiation). </li>
                    <li>High level of operator safety thanks to menu-assisted operation in plaintext without manual</li>
                    <li>The measuring ray bundle passes through the sample chamber into which the sample gas is flowing, and is attenuated as a
                        function of the concentration of the measured component. </li>
                    <li>This is evaluated according to the Lambert-Beer absorption law.</li>
                    <li>The measuring radiation is recorded by a photodiode downstream of the sample chamber into which the sample gas is flowing (measuring signal),
                        the reference radiation by a second photodiode (reference signal). </li>
                    <li>The ratio of measured signal and reference signal is used to calculate the concentration of the gas component. </li>
                    <li>The beam splitter also enables the coupling of a second light source for measuring a second gas component. </li>
                    <li>The beam splitter also enables the coupling of a second light source for measuring a second gas component. </li>
                    <li>In this way, the absorption of sulfur dioxide (SO2) and nitrogen dioxide (NO2)
                        is measured in alternating cycles and converted into continuous concentration values in sensor-level electronics. </li>
                    <li>Additional sample gas applications are possible through a suitable selection of LEDs.</li>
                </ul>
                <div className="mb-3">
                    <h6>
                        Technical specifications
                    </h6>
                </div>
                <table className="table text-white table-bordered">

                    <tbody>


                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            General information
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Measured components
                        </td>
                        <td>
                            Maximum of 4

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Measuring ranges
                        </td>
                        <td>
                            2 per measured component

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Display
                        </td>
                        <td>
                           LCD with LED backlighting and contrast control;
                            function keys; 80 characters (4 lines/20 characters)

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Operating position

                        </td>
                        <td>
                           Front wall, vertical

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Conformity

                        </td>
                        <td>
                            CE marking EN 61000-6-2, EN 61000-6-4

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Design, enclosure
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Degree of protection, 19" rack unit
                        </td>
                        <td>
                           7MB2335, 7MB2337 and 7MB2338: IP20 according to EN 60529

                        </td>
                    </tr>
                    <tr>
                        <td>
                            and desktop model
                        </td>
                        <td>
                            7MB2335, 7MB2337 and 7MB23338: IP40 according to EN 60529

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Time response
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Warm-up  period <br/>
                        </td>
                        <td>
                            Approx 30 min (at room temperature); the
                            technical specification will be met after 2 h

                        </td>
                    </tr>
                    <tr>
                        <td>
                           delayed display (T90 time)
                        </td>
                        <td>
                            Dependent on length of analyser chamber,
                            sample gas line and configurable attenuation

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Damping (electrical time constant)
                        </td>
                        <td>
                           Configurable from 0 ... 99.9s

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Drift
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Zero point
                        </td>
                        <td>
                          1% of the current measuring range/week

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Full-scale value drift
                        </td>
                        <td>
                            1% of the current measuring range/week

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Electrical inputs and outputs
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                           Analog output
                        </td>
                        <td>
                           Per component, 0/2/4 ...20 mA, NAMUR, isolated, max.load 750&#8486;

                        </td>
                    </tr>
                    <tr>
                        <td>
                            relay outputs
                        </td>
                        <td>
                           8, with changeover contacts, freely configurable, e.g. for measuring range identification; 24V AC/DC/1 A Load, potential-free, non-sparking

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Digital inputs
                        </td>
                        <td>
                           3, dimensioned for 24 v, potential-free <br/>
                            - Pump <br/>
                            - AUTOCAL <br/>
                            - Synchronization
                        </td>
                    </tr>
                    <tr>
                        <td>
                           Serial interface
                        </td>
                        <td>
                           RS 485
                        </td>
                    </tr>
                    <tr>
                        <td>
                            AUTOCAL function
                        </td>
                        <td>
                           Automatic unit calibration with ambient air (depending on measured component);
                            adjustable cycle time from 0 (1) ...24 hours

                        </td>
                    </tr>
                    <tr>
                        <td>
                          Options
                        </td>
                        <td>
                          Add-on electronics, each with 8 additional digital inputs and relay outputs,
                            e.g. for triggering of automatic calibration and for PROFIBUS PA or PROFIBUS DP

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Climatic conditions
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                           Permissible ambient temperature during operation
                        </td>
                        <td>
                          - +5 ... 45 <sup>o</sup>C (IR-detector, O<sub>2</sub>) <br/>
                          - +5 ... 40 <sup>o</sup>C (H<sub>2</sub>S-Sensor) <br/>
                          - +15 ... 35 <sup>o</sup>C (UV-photometer)

                        </td>
                    </tr>
                    </tbody>
                </table>


            </div>

        </>
    )
}

export default Ultramat23Cabinet;