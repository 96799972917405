import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_ULTRAMAT_6_1_675x267px.png";

const Ultramat6 = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                       For demanding applications
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="Ultramat6" src={UltramtBild}/>
                </div>


                <p>
                    The ULTRAMAT 6 analyzer module, single- or dual-channel, measures gases whose absorption bands in the infrared wavelength range are between 2 and 9 µm, such as CO,
                    CO<sub>2</sub>, NO, SO<sub>2</sub>, NH<sub>3</sub>, H<sub>2</sub>O as well as CH<sub>4</sub> and other hydrocarbons, highly selectively. Single-channel analyzers can simultaneously measure up to 2 gas components, while dual-channel analyzers can simultaneously measure 3 (or 4 on request) gas components

                </p>
                <h6>
                    Applications
                </h6>

                <ul>
                    <li>Measurement for boiler control in incineration plants</li>
                    <li> Emission measurements in incineration plants</li>
                    <li>Measurement in the automotive industry (test benches)</li>
                    <li>Warning equipment</li>
                    <li>Process gas concentrations in chemical plants</li>
                    <li>Trace measurements in ultra-pure gas processes</li>
                    <li>Environmental protection</li>
                    <li>TLV (Threshold Limit Value) monitoring at workplaces</li>
                    <li>Quality monitoring</li>
                    <li>Ex versions for analyzing flammable and non-flammable gases or vapors for use in hazardous areas</li>
                </ul>


                <h6>
                    Sectors
                </h6>


                <ul>
                    <li>Automotive industry</li>
                    <li>Chemical industry</li>
                    <li>Power plants</li>
                    <li>Waste incineration plants</li>
                    <li> Research and development</li>

                </ul>


                <h6>Advantages</h6>

                <ul>
                    <li>High selectivity with double-layer detector and optical coupler</li>
                    <li>Reliable measurements even in complex gas mixtures</li>
                    <li>Low detection limits</li>
                    <li>Measurements with low concentrations</li>
                    <li>Corrosion-resistant materials in gas path (option)</li>
                    <li>Measurement possible in highly corrosive sample gases</li>
                    <li>Cost savings thanks to reuse of the local, cleanable cells </li>
                    <li>Electronics and physics: gas-tight isolation, purging is possible, IP65</li>
                    <li>lifelong-living even in harsh environments </li>
                    <liv>Heated versions (option)</liv>
                    <liv>Use also in presence of gases condensing at low temperature Ex(p) for Zones 1 and 2 (in accordance with ATEX 2G and ATEX 3G)</liv>

                </ul>



            </div>

        </>
    )
}

export default Ultramat6;