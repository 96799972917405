import React from "react";

const Text1NaturalGas = () => {
    return(
        <div className="container">
            <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                <h5>
                    44th BImSchV - German regulation for medium<br/>
                    combustion plants
                </h5>
            </div>
            <h6>
                The German 44th BImSchV regulation for small- and medium-size
                plants covers the continuous monitoring of air pollutant emission
                values for levels of:
            </h6>

            <ul>
                <li> Dust</li>
                <li> Carbon monoxide(CO)</li>
                <li> oxides of nitrogen (NO<sub>x</sub>)</li>
                <li> Sulphur dioxide (SO<sub>2</sub>)</li>
            </ul>


            <h6>
                Plant-type specific also:
            </h6>


            <ul>
                <li> Total organic carbon (TOC)</li>
                <li>Ammonia (NH <sub>3</sub>)</li>
            </ul>


            <h6>Complete AMS (CEMS) needs to be QAL1 certified according to
                EN14181 => TÜV/UBA approval</h6>


        </div>
    )
}

export default Text1NaturalGas;