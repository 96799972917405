import React from "react";

const Text2SolidBiomass = () => {
    return(
        <div className="container">
            <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                <h5>
                    MCPD - European MCPD Medium Combustion<br/>
                    Plant Directive 2015/2193/EU

                </h5>
            </div>
            <h6>
                The 2015/2193/EU MCP directive regulates pollutant emissions from
                the combustion of fuels in around 143,000 plants with a rated thermal
                input of between 1 and 50 MWth. Plant-type specific, continuously
                monitored emission limit values (ELVs) especially for:

            </h6>

            <ul>
                <li> Dust</li>
                <li> Oxides of nitrogen (NO<sub>x</sub>)</li>
                <li>Sulphur dioxide (SO<sub>2</sub>)</li>
            </ul>


            <h6>
                For referencing and cross-correlation purposes additional<br/>
                measurement components commonly used:
            </h6>


            <ul>
                <li>Oxygen (O<sub>2</sub>), volume flow</li>
            </ul>


            <h6>Analyser within AMS(CEMS) should be QAL1 certified according to
                EN14181 => MCERTS or TÜV/UBA approval according to EN15267</h6>


        </div>
    )
}

export default Text2SolidBiomass;