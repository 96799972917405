import React from "react";

const TestCoral2 = () => {
    return(
        <div className="container">
            <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                <h5>
                    European IED - Industrial Emissions Directive <br/>
                    2010/75/EU, With Annex V for LCP
                </h5>
            </div>
            <h6>
                The 2010/75/EU industrial Emissions Directive, with Annex V for large
                Combustion plants, requires plant-type specific, continuously
                monitored emission limit values in general for:
            </h6>

            <ul>
                <li> Dust</li>
                <li> oxides of nitrogen (NO<sub>x</sub>)</li>
                <li> Sulphur dioxide (SO<sub>2</sub>)</li>
            </ul>

            <div>
                <h6>
                    In some cases/applications:
                </h6>
            </div>

            <ul>
                <li> Total organic carbon (TOC)</li>
                <li>Ammonia (NH<sub>3</sub>)</li>
            </ul>


            <h6>Analyser within AMS (CEMS) should be QAL1 certified according to
                EN14181 => MCERTS or TÜV/UBA approval according to EN15267
            </h6>


        </div>
    )
}

export default TestCoral2;