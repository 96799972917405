import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_ULTRAMAT_6_1_675x267px.png";


import DemoCarousel from "./DemoCaroussel";
import co from "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import Ultramat6Grafik from "./media/touchapp-schaltschraenke-grafik3-en-2000x2000px.png";
import Ultramat6BildSlider from "./media/Touch-App-Geraeteabbildung_ULTRAMAT_6_2_675x267px.png";
import NH3 from "./media/touchapp-chemical-icon-nh3-64x64px.png";
import H2o from "./media/touchapp-chemical-icon-h2o-64x64px.png";

const Ultramat6Cabinet = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                        Highly selective thanks to double-layer detector and optical coupler

                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    {/* <img alt="Ultramat" src={UltramtBild}/> */}
                    <DemoCarousel image1={UltramtBild} image2={Ultramat6BildSlider}/>
                </div>
                <div className="d-flex justify-content-around mb-3">
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img alt="carbon oxides" style={{width:"100%"}} src={co} />
                        <span>carbon oxides</span>

                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon" >
                        <img alt="hydrocarbons" style={{width:"100%"}} src={cnhm}/>
                        <span>hydrocarbons</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon"  >
                        <img alt="sulfur dioxides" src={so2} style={{width:"100%"}}/>
                        <span>sulfur dioxides</span>

                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon " >
                        <img alt="nitrogen oxides" src={nox}  style={{width:"100%"}}/>
                        <span>nitrogen oxides</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon" >
                        <img alt="ammonia" src={NH3}  style={{width:"100%"}}/>
                        <span>ammonia</span>
                    </div>
                    <div className="d-flex align-items-center flex-column Chemicalicon "  >
                        <img alt="water" style={{width:"100%"}} src={H2o} />
                        <span>water</span>

                    </div>



                </div>

                <h6>
                    how it works
                </h6>

                <p>
                    The ULTRAMAT 6 gas analyzer uses the infrared two-beam alternating light principle with double-layer detector and optical coupler.
                    The measuring principle is based on the molecule-specific absorption of bands of infrared radiation. The absorbed wavelengths are characteristic of the individual gases, but may partially overlap. This results in cross-sensitivities which
                    are reduced to a minimum by the following measures:

                </p>
                <ul>
                    <li>Gas-filled filter chamber (beam splitter)</li>
                    <li>Double-layer detector with optical coupler</li>
                    <li>Optical filters if necessary</li>

                </ul>


                <h6>
                    Measuring principle of infrared measurement:
                </h6>
                <div className="d-flex justify-content-center align-items-center mt-3 mb-3" >
                    <img alt="Ultramat" className="Ultramat6Grafik"   src={Ultramat6Grafik}/>

                </div>



                <ul>
                    <li>An IR source, which is heated to approx. 700 °C and which can be shifted to balance the system, is divided by the beam splitter (acts as a filter chamber
                        at the same time) into two equal beams (sample and reference beams). </li>
                    <li>The reference beam passes through a reference chamber filled with N2 (a non-infrared-active gas) and reaches the right-hand side of the receiver chamber practically unattenuated..</li>
                    <li>At the same time, the sample beam passes through the sample chamber through which the sample gas flows and reaches the left-hand side of the receiver chamber attenuated to a lesser or greater extent depending on the concentration of the sample gas. </li>
                    <li>The receiver chamber is filled with a defined concentration of the gas component to be measured. </li>
                    <li>The center of the absorption band is preferentially absorbed in the upper detector layer of the double-layer detector. </li>
                    <li>The edges of the band are absorbed to approximately the same extent in the upper and lower layers.  </li>
                    <li>The upper and lower detector layers are connected together via the microflow sensor </li>
                    <li>This coupling means that the spectral sensitivity has a very narrow band </li>
                    <li>The optical coupler lengthens the lower receiver chamber layer
                        optically.
                    </li>
                    <li>The infrared absorption in the second receiver chamber layer is varied by changing the slider position.  It is thus possible to individually minimize the influence of interfering components. </li>
                    <li>A chopper rotates between the beam splitter and the sample chamber and interrupts the two beams alternately and periodically. If absorption takes place in the sample chamber, a pulsating flow is generated between the two detector levels which is converted by the microflow sensor
                        into an electric signal.
                    </li>
                    <li>The microflow sensor consists of two nickel-plated grids heated to approximately 120 °C,
                        which, along with two supplementary resistors, form a Wheatstone bridge. • The pulsating flow together with the dense
                        arrangement of the Ni grids causes a change in resistance.

                    </li>
                    <li>This leads to an offset in the bridge which is proportional to the concentration of sample gas.

                    </li>
                </ul>


                <h6>Notes:</h6>

                <ul>
                    <li>The sample gases must be  free of dust when led ed into the analyzers. </li>
                    <li>Condensation in the sample chambers must be prevented. Therefore, the use of a an according sample preparation is necessary in most applications. </li>
                    <li>As far as possible, the ambient air of the analyzer should not have a large concentration of the gas components to be measured.  </li>
                    <li>Flow-type reference sides with reduced flow must not be operated with flammable or toxic gases</li>
                    <li>Flow-type reference sides with reduced flow and an O2 content > 70% may
                        only be used in conjunction with Y02 (cleaned for O2).
                    </li>
                    <li>Channels with electronically suppressed zero point only differ from the standard version in the measuring range parameterization.</li>
                    <li>Physically suppressed zeros can be provided as a special application. </li>

                </ul>
                <div className="mb-3">
                    <h6>
                        19" rack unit
                    </h6>
                </div>
                <div className="mb-3">
                    <h6>
                        Technical specifications
                    </h6>
                </div>
                <table className="table text-white table-bordered">

                    <tbody>


                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            General information
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Measured ranges
                        </td>
                        <td>
                           4, internally and externally switchable; autoranging is also possible

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Smallest possible measuring range
                        </td>
                        <td>
                           Dependent on the application: e.g. CO:0...10 vpm, CO<sub>2</sub>:0...5 vpm

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Largest possible measuring span
                        </td>
                        <td>
                           Dependent on the application

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Measuring range with suppressed zero point

                        </td>
                        <td>
                           Any zero point within 0 ... 100 vol.% can be implemented; smallest possible span 20%

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Operating position

                        </td>
                        <td>
                            Front wall, vertical

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Conformity

                        </td>
                        <td>
                            CE mark in accordance with EN 50081-1, EN 50082-2

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Influence of interfering gases must be considered separately

                        </td>
                        <td>


                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Design, enclosure
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Weight
                        </td>
                        <td>
                            Approx. 15 kg (with one IR channel) <br/>
                            Approx. 21 kg (with two IR channels)

                        </td>
                    </tr>
                    <tr>
                        <td>
                          Degree of protection
                        </td>
                        <td>
                            IP20 according to EN 60529

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                          Dynamic response
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Warm-up period
                        </td>
                        <td>
                            At room temperature &lt; 30 min ( the technical specification will be met after 2 hours)

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Delayed display (T90-time)
                        </td>
                        <td>
                           Dependent on length of analyzer chamber, sample gas line and configurable damping

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Drift
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Zero point drift
                        </td>
                        <td>
                            &lt; &#177; 1% of the current measuring range/week

                        </td>
                    </tr>
                    <tr>
                        <td>
                           Endwertdrift
                        </td>
                        <td>
                            &lt; &#177; 1% of the current measuring range/week

                        </td>
                    </tr>

                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Electrical inputs and outputs
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Analog output
                        </td>
                        <td>
                            0/2/4 ... 20mA, isolated; load  750&#8486;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            relay outputs
                        </td>
                        <td>
                            6, with changeover contacts,
                            freely configurable, e.g. for measuring range identification; load: 24 V AC/DC/1 A,
                            isolated, non-sparking


                        </td>
                    </tr>
                    <tr>
                        <td>
                           Analog inputs
                        </td>
                        <td>
                            2, dimensioned for 0/2/4 ... 20 mA for external pressure sensor and accompanying gas influence
                            correction (correction of cross-interference)


                        </td>
                    </tr>
                    <tr>
                        <td>
                            Digital inputs
                        </td>
                        <td>
                           6, designed for 24 V, isolated, freely configurable, e.g. for measuring range switchover
                        </td>
                    </tr>
                    <tr>
                        <td>
                            serial interface
                        </td>
                        <td>
                           RS 485

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Options
                        </td>
                        <td>
                          AUTOCAL function each with 8 additional digital inputs and relay outputs, also with PROFIBUS PA or PROFIBUS DP

                        </td>
                    </tr>
                    <tr className="table-dark">
                        <td colSpan="2"><h6>
                            Climatic conditions
                        </h6></td>
                    </tr>
                    <tr>
                        <td>
                            Permissible ambient temperature
                        </td>
                        <td>
                            - +5 ... 45 <sup>o</sup>C during operation


                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </>
    )
}

export default Ultramat6Cabinet;