import React from "react";
import MenuBoutton from "./media/Menuebutton_wei├ƒ.png";
import homeBoutton from "./media/home.PNG";
import solidBiomass from "./media/solid_biomass_fired_mcp_wei├ƒ_klein.png";
import naturalGas from "./media/natural_gas_wei├ƒ_klein.png";
import coalFired from "./media/coal_fired_lcp_wei├ƒ_klein.png";
import biomassFired from "./media/biomass_fired_lcp_wei├ƒ_klein.png";
import coIncineration from "./media/co-incieration_solids_wei├ƒ_klein.png";
import cementPlan from "./media/cement_plant_klein.png";
import {Link} from "react-router-dom";


class VerticalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "none"
        }
    }

    handleClick = () => {
        if (this.state.display === "none") {
            this.setState({display: "block"})

        } else {
            this.setState({display: "none"})

        }
    }

    render() {
        const myStyle = {
            borderBottom: "solid rgba(115, 115, 115, 0.57) 1px",
            bottom: "0px",
            right: "0px",
            width: "100%",
            height: "16%",
            backgroundColor: "rgba(0, 0, 0, 0.42)",
            cursor: "pointer",
            display: this.state.display,
            fontSize: "70%"

        };

        return (
            <div className=" sideMenuContainer d-flex mr-1  justify-content-end flex-column align-items-end">

                <div style={{bottom: "0px", right: "0px", width: "45px", height: "45px", cursor: "pointer"}}
                     onClick={this.handleClick}>
                    <img src={MenuBoutton} alt="MenuBoutton" width="100%" height="100%"/>
                </div>

                <div onClick={() => window.location.reload(false)} className=" sideMenu " style={myStyle}>
                    <Link to={{ pathname:'/pagecontainer/solidbiomass' }} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            SOLID BIOMASS FIRED

                            <img src={solidBiomass} alt="solidBiomass" height="80%"/>
                        </div>
                    </Link>

                </div>

                <div onClick={() => window.location.reload(false)} className=" sideMenu" style={myStyle}>
                    <Link to={{pathname:'/pagecontainer/naturalgas'}} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            NATURAL GAS FIRED TURBINE

                            <img src={naturalGas} alt="naturalGas" height="80%"/>
                        </div>
                    </Link>

                </div>
                <div onClick={() => window.location.reload(false)} className=" sideMenu" style={myStyle}>
                    <Link to={{pathname:'/pagecontainer/coalfiredlcp'}} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            COAL FIRED LCP

                            <div>
                                <img src={coalFired} alt="coalFired" height="80%" style={{marginRight: "-5px"}}/>
                            </div>
                        </div>
                    </Link>

                </div>


                <div onClick={() => window.location.reload(false)} className=" sideMenu" style={myStyle}>
                    <Link to={{pathname:'/pagecontainer/biomassfiredlcp'}} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            BIOMASS FIRED LCP

                            <img alt="biomassFired" src={biomassFired} height="80%"/>
                        </div>
                    </Link>

                </div>
                <div onClick={() => window.location.reload(false)} className=" sideMenu" style={myStyle}>
                    <Link to={{pathname:'/pagecontainer/coincinerationofsolids'}} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            CO-INCINERATION SOLIDS

                            <img alt="{coIncineration" src={coIncineration} height="80%"/>
                        </div>
                    </Link>

                </div>
                <div onClick={() => window.location.reload(false)} className="sideMenu" style={myStyle}>
                    <Link to={{pathname:'/pagecontainer/cementplant'}} style={{textDecoration: 'none', color: "white"}}>
                        <div className="d-flex justify-content-between pt-2 pl-2 w-100 h-100">

                            CLEMENT PLANT

                            <img alt="cementPlan" src={cementPlan} height="80%"/>
                        </div>
                    </Link>

                </div>


                <div onClick={() => window.location.reload(false)} className=" mt-1" style={{
                    borderBottom: "solid rgba(115, 115, 115, 0.57) 1px",
                    width: "45px",
                    height: "45px",
                    cursor: "pointer"
                }}>
                    <Link to={'/'} style={{textDecoration: 'none', color: "white"}}>

                        <img alt="homeBoutton" src={homeBoutton} width="100%" height="100%"/>
                    </Link>

                </div>


            </div>

        )
    }
}

export default  VerticalMenu;