import React from "react";
import Gerät1 from "./media/Schaltschrank_1_Geraet.png";


const Schrank1Gerät = () => {
    return (
        <>

            <img src={Gerät1} alt="schrank"  width="135%" height="97%"/>


        </>
    )
}

export default React.memo(Schrank1Gerät);