import React from "react";
import Caroussel from "./Caroussel";
import UltramtBild2 from  "./media/Touch-App-Geraeteabbildung_Ultramat_23_2_675x267px.png";
import UltramtBild from "./media/sie-rack-device-persp1-fr-001-39l-ohne-bearbeitet_675x267-freigestellt (002).png";
import DemoCarousel from "./DemoCaroussel";
import co from  "./media/touchapp-chemical-icon-co-64x64px.png";
import cnhm from "./media/touchapp-chemical-icon-cnhm-64x64px.png";
import so2 from "./media/touchapp-chemical-icon-so2-64x64px.png";
import nox from "./media/touchapp-chemical-icon-no-64x64px.png";
import sf6 from "./media/touchapp-chemical-icon-sf6-64x64px.png";
import h2s from "./media/touchapp-chemical-icon-h2s-64x64px.png";
import o2 from "./media/touchapp-chemical-icon-o2-64x64px.png";
import UltramatGrafik1 from "./media/touchapp-schaltschraenke-grafik1-en-2000x2000px.png"
const Ultramat23 = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                       Continuous, simultaneous measurement of <br/>
                       up to four gas components
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center" >
                     <img alt="Ultramat" src={UltramtBild}/>

                </div>


                <p>
                    The ULTRAMAT 23 is an innovative, multi-component gas analyzer and can be equipped
                    with the following sensors: IR detector for IR-active gases, UV photometer for UV-active gases,
                    H<sub>2</sub>S sensor (electrochemical), O<sub>2</sub> sensor (electrochemical or paramagnetic), and used accordingly in numerous applications and sectors.
                </p>
                <h6>
                    Applications
                </h6>


                <ul>
                    <li>Optimization of small firing systems</li>
                    <li> Monitoring of exhaust gas concentration from firing systems with all types of fuel (oil,
                        gas and coal) as well as operational measurements with thermal incineration plants</li>
                    <li>Monitoring of air in fruit stores, greenhouses, fermenting cellars and warehouses</li>
                    <li> Monitoring of process control functions</li>
                    <li>Atmosphere monitoring during heat treatment of steel</li>
                    <li>For use in non-hazardous atmospheres</li>
                    <li>Flue gas analysis</li>
                    <li>Inerting plants</li>
                    <li>Room air monitoring </li>
                </ul>


                <h6>
                    Sectors
                </h6>


                <ul>
                    <li>Chemical plants</li>
                    <li>Cement industry</li>
                    <li>Food and beverages</li>
                    <li>Breweries</li>
                    <li>Waste incineration plants</li>
                    <li>Refineries</li>
                    <li>Power plants</li>
                    <li>Steel works</li>
                </ul>


                <h6>Advantages</h6>

                <ul>
                    <li>Highly cost effective because calibration gases are not required (AUTOCAL with ambient air, dependent on the measured component)</li>
                    <li>High selectivity thanks to multi-layer detectors, e.g. low cross-sensitivity to water vapor</li>
                    <li>Costs savings thanks to reuse of the local, cleanable cells</li>
                    <li>High level of operator safety thanks to menu-assisted operation in plaintext without manual</li>
                </ul>

            </div>

        </>
    )
}

export default Ultramat23;