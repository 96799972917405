

const initalstate = {
    boxText: 'null',
     display: 'none',
     displayTitle:false,
     displayBox:"flex",
     cabConfig4:"cabConfig",
     displayReturn:"none",
     justifyCon: "flex-end",
     displayConfigur:'none',
     schrankWidth:'100%',
     dispaySchrankOpen:true,
    displayButtonOpenCabinet:false,
    displayButtonCloseCabinet:false,
    isCabinetOpen:false,
    marginLeftCabinet: "",
    boxDisplay: "none",

}

const reducer = (state = initalstate, action) => {
    if(action.type === 'CHANGEBOXTEXT1') {
  if (state.isCabinetOpen) {
      switch(action.payload) {
          case  "ULTRAMAT 231" :
          return {
          ...state,
          boxText: action.payload,
          boxTextTitle:"ULTRAMAT 23",
          displayTitle: true,
          displayBox: "none",
          cabConfig4: "cabConfig4",
          displayReturn: "block",
          justifyCon: "space-between",
          displayConfigur: 'none',
          displayButtonOpenCabinet: false
      }
          case "ULTRAMAT 61" :
              return {
                  ...state,
                  boxText: action.payload,
                  boxTextTitle:"ULTRAMAT 6",
                  displayTitle: true,
                  displayBox: "none",
                  cabConfig4: "cabConfig4",
                  displayReturn: "block",
                  justifyCon: "space-between",
                  displayConfigur: 'none',
                  displayButtonOpenCabinet: false
              }
          case "LDS61" :
              return {
                  ...state,
                  boxText: action.payload,
                  boxTextTitle:"LDS6",
                  displayTitle: true,
                  displayBox: "none",
                  cabConfig4: "cabConfig4",
                  displayReturn: "block",
                  justifyCon: "space-between",
                  displayConfigur: 'none',
                  displayButtonOpenCabinet: false
              }
          case "FIDAMAT 61" :
              return {
                  ...state,
                  boxText: action.payload,
                  boxTextTitle:"FIDAMAT 6",
                  displayTitle: true,
                  displayBox: "none",
                  cabConfig4: "cabConfig4",
                  displayReturn: "block",
                  justifyCon: "space-between",
                  displayConfigur: 'none',
                  displayButtonOpenCabinet: false
              }
          case "DURAG HM- 1400 TRX1" :
              return {
                  ...state,
                  boxText: action.payload,
                  boxTextTitle:"DURAG HM- 1400 TRX",
                  displayTitle: true,
                  displayBox: "none",
                  cabConfig4: "cabConfig4",
                  displayReturn: "block",
                  justifyCon: "space-between",
                  displayConfigur: 'none',
                  displayButtonOpenCabinet: false
              }
      }
  } else {
      return {
          ...state,
          boxText: action.payload,
          boxTextTitle: action.payload,
          displayTitle: true,
          displayBox: "none",
          cabConfig4: "cabConfig4",
          displayReturn: "block",
          justifyCon: "space-between",
          displayConfigur: 'none',
          displayButtonOpenCabinet: true
      }
  }
    }
    if(action.type === 'CHANGEBOXTEXT2') {
        return {
            ...state,
            boxText: action.payload,
            display:'block',
            displayTitle: false,
            displayConfigur: 'block'

        }
    }
    if(action.type === 'CHANGEBOXTEXT3') {
        return {
            ...state,
            boxText: action.payload,
            display: 'block',
            displayTitle: false,
            displayConfigur: 'block'

        }
    }
    if(action.type === 'PREVIOUSSTATE') {
        return {
            ...state,
            justifyCon: "flex-end",
            displayReturn:"none",
            cabConfig4:"cabConfig",
            displayTitle:false ,
            displayBox: "flex",
            boxText: "case1",
            displayConfigur: 'block',
            displayButtonOpenCabinet:false,
            displayButtonCloseCabinet:false,
            dispaySchrankOpen:true,
            isCabinetOpen:false,
            marginLeftCabinet:"",
            boxDisplay: "none",
            boxTextTitle: ""





        }
    }
    if(action.type === 'CHANGEBOXTEXT4') {

        switch (action.payload) {
            case "ULTRAMAT 23":
                return {
                    ...state,
                    boxText: 'ULTRAMAT 231',
                    schrankWidth:'120%',
                    dispaySchrankOpen:false,
                    displayButtonCloseCabinet: true,
                    displayButtonOpenCabinet: false,
                    isCabinetOpen: true,
                    marginLeftCabinet:"ml-5"

                }
            case "ULTRAMAT 6" :
                return {
                    ...state,
                    boxText: 'ULTRAMAT 61',
                    schrankWidth:'120%',
                    dispaySchrankOpen:false,
                    displayButtonOpenCabinet: false,
                    displayButtonCloseCabinet:true,
                    isCabinetOpen: true,
                    marginLeftCabinet:"ml-5"
                }
            case "LDS6":
            return {
                ...state,
                boxText: 'LDS61',
                schrankWidth:'120%',
                dispaySchrankOpen:false,
                displayButtonOpenCabinet: false,
                displayButtonCloseCabinet:true,
                isCabinetOpen: true,
                marginLeftCabinet:"ml-5"
                }
            case "FIDAMAT 6" :
                return {
                    ...state,
                    boxText: "FIDAMAT 61",
                    schrankWidth:'120%',
                    dispaySchrankOpen:false,
                    displayButtonOpenCabinet: false,
                    displayButtonCloseCabinet:true,
                    isCabinetOpen: true,
                    marginLeftCabinet:"ml-5"
                }
            case "DURAG HM- 1400 TRX" :
                return {
                    ...state,
                    boxText: "DURAG HM- 1400 TRX1",
                    schrankWidth:'120%',
                    dispaySchrankOpen:false,
                    displayButtonOpenCabinet: false,
                    displayButtonCloseCabinet:true,
                    isCabinetOpen: true,
                    marginLeftCabinet:"ml-5"
                }


        }
    }
    if (action.type === "CLOSECABINET" ) {
        switch (state.boxText) {
            case "ULTRAMAT 231":
                return {
                    ...state,
                    boxText: "ULTRAMAT 23",
                    isCabinetOpen:false,
                    dispaySchrankOpen:true,
                    displayButtonOpenCabinet:true,
                    marginLeftCabinet:""

                }
            case "ULTRAMAT 61":
                return {
                    ...state,
                    boxText: "ULTRAMAT 6",
                    isCabinetOpen:false,
                    dispaySchrankOpen:true,
                    displayButtonOpenCabinet:true,
                    marginLeftCabinet:""

                }
            case "LDS61":
                return {
                    ...state,
                    boxText: "LDS6",
                    isCabinetOpen:false,
                    dispaySchrankOpen:true,
                    displayButtonOpenCabinet:true,
                    marginLeftCabinet:""

                }
            case "FIDAMAT 61":
                return {
                    ...state,
                    boxText: "FIDAMAT 6",
                    isCabinetOpen:false,
                    dispaySchrankOpen:true,
                    displayButtonOpenCabinet:true,
                    marginLeftCabinet:""

                }
            case "DURAG HM- 1400 TRX1":
                return {
                    ...state,
                    boxText: "DURAG HM- 1400 TRX",
                    isCabinetOpen:false,
                    dispaySchrankOpen:true,
                    displayButtonOpenCabinet:true,
                    marginLeftCabinet:""

                }


        }



    }
    if (action.type === "SHOWCONFIGURATION") {
        return {
            ...state,
            boxDisplay: true
        }
    }


    return state;


}

export default reducer;