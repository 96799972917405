import React from 'react';
import logo from './media/Siemens.PNG';
import schrank from './media/schrank.png';
import schrank2 from './media/Schaltschrank_Fla╠êchen.png';
import Fidanmat from './media/FIDAMAT_ULTRAMAT6_LDS6.png';
import ButtonOpencabinet from "./ButtonOpencabinet";

import schrank1 from './media/Schaltschrank_mit_Gera╠êten_komplett.png'
import symboleRecht from './Symbole_rechts.png';
import ReactTooltip from "react-tooltip";
import VerticalMenu from "./VerticalMenu";
import CabinetConfiguration from "./CabinetConfiguration";
import uniqid from 'uniqid';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import ButtonCloseCabinet from "./ButtonCloseCabinet";
import Ultramat23Cabinet from "./media/ULTRAMAT23.png";



class PageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.CabinetElement = React.createRef();
        this.state = {
           // boxDisplay: "none",

        }

    }
    componentDidMount() {
        window.onpopstate = ()=> {
            window.location.reload(false)
        }

    }


    /*click = () => {
        this.setState({boxDisplay: "block"})
    }*/

    handleClick = (param) => {
        this.CabinetElement.current.handleText(param)


}


    render() {


        const {match, data} = this.props;
        const datas = data.filter(({id}) => id === match.params.id);
        const myStyle = {
            display: `${this.props.boxdispla}`,
            lineHeight: "15px",
            position:"relative"

    };
        const schrankstyl1 = {
            width:"100%",
            heigh:"97%"

        }
        const schrankstyl2 = {
            width:'150%',
            heigh:"97%"

        }


        return (
            <>
                <div className="row no-gutter bg1 " style={{height: "100%", position: "relative"}}>
                    <div className="col-12 ">
                        <div className="row" style={{width: "100%"}}>
                            <div className="col-5 d-flex align-items-center ml-3">
                                <h3>
                                    { this.props.displaytitl ? `${this.props.changeTitle}` : `${datas[0].title}` }
                                </h3>

                            </div>
                            <div className="col-2 mr-auto ">

                            </div>
                            <div className="col-2 d-flex flex-row mt-1 justify-content-end">
                                <Link to={'/'}>
                                <img src={logo} alt="logo" width="70%"/>
                            </Link>
                            </div>
                        </div>
                        <div className="row" style={{height: "90%", width: "100%"}}>
                            <div className="col-6" style={{height: "100%"}}>
                                <CabinetConfiguration ref={this.CabinetElement} box={this.props.boxt} title1={datas[0].subTitle1} title2={datas[0].subTitle2}
                                                      click1={this.click}/>
                            </div>
                            <div className="col-6" style={{height: "100%"}}>
                                <div className="row no-gutter" style={{height: "100%"}}>
                                    <div className="col-4 pt-0 " style={{height: "100%" , position: "relative"}}>
                                        {this.props.displayButtonCloseCabine ? <ButtonCloseCabinet/>: ''}

                                        {this.props.displayButtonOpenCabine ? <ButtonOpencabinet ValueOfCabinet={ this.props.boxt}/> : ''}

                                        { this.props.dispaySchrankOpe ? <img alt="schrank"  src={schrank} width="100%" height="97%"/> : datas[0].gerät  }
                                    </div>
                                    <div className="col-8 paddingBox" style={{maxHeight: "100%"}}>
                                        <div className= "row no-gutter "  style={{height: "100%"}} >
                                            <div className="col-12   pt-2 ">
                                                <div className="d-flex align-items-start flex-column   ml-1"
                                                     style={{height: "100%", position: "relative"}}>
                                                    {datas[0].config.map((conf) => <button  value={this.props.isCabinetOpe ?`${conf.title}1` :conf.title} key={uniqid()} style={myStyle} onClick={(e) => this.props.changeTextBox1(e.target.value)}
                                                                                                          className={`mb-2  ml-2 configbox1  text-white  ${this.props.changeTitle === conf.title ? 'buttonActive':'buttonActive1' } ` }>
                                                        {/*<a href={`https://new.siemens.com/global/en/products/automation/process-analytics/extraktive-continuous-process-gas-analytics/${conf.title ==='ULTRAMAT 23'?'ultramat-23.html':'series-6.html'}`}
                                                            className="w-100 h-100 d-flex pl-1 pt-1 justify-content-start  flex-column text-white"
                                                            style={{position: "relative",textDecoration:"none" }}>*/}


                                                         {conf.title} <br/>
                                                        {/*<div className="d-flex align-items-center mt-1 subtitle">*/}
                                                        {/*<div <span  style={{fontSize: "12px"}}>  {conf.subtitle} </span>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="arrowContainer">
                                                                <svg viewBox="0 0 16 16"
                                                                     className="bi bi-chevron-right arrow"
                                                                     fill="currentColor"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd"
                                                                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                                                </svg>
                                                            </div>*/}

                                                        { conf.title==="DURAG HM- 1400 TRX" ?'' : <svg style={{position:"absolute", left:"-10px", top:"50%"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                              className={ `bi bi-caret-left-fill ${this.props.changeTitle === conf.title ? 'icon1' : 'icon'}`}
                                                             viewBox="0 0 16 16">
                                                            <path
                                                                d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                                        </svg>}



                                                    </button>  )}

                                                    <div className= {`pr-2 ${this.props.marginLeftCabine}`} style={{position: "absolute", bottom: "0px"}}>
                                                        <div id="tooltipBox1" data-event="click focus" data-tip
                                                             data-for='global1'></div>

                                                        <div id="tooltipBox2" data-event="click focus" data-tip
                                                             data-for='global'></div>
                                                        <div id="tooltipBox3" data-event="click focus" data-tip
                                                             data-for='global2'></div>
                                                        <ReactTooltip id='global' globalEventOff='click' place="top"
                                                                      type="dark" effect="solid">
                                                            <p>Whether for pipelines, valves or reactors - the
                                                                SITRANS P <br/> transmitters determine relative,
                                                                absolute or differential pressure <br/>
                                                                and report pressures that are too low or potential
                                                                overloads.<br/>
                                                                Also suitable for safety-oriented applications.
                                                            </p>

                                                        </ReactTooltip>

                                                        <ReactTooltip id='global1' globalEventOff='click' place="top"
                                                                      type="dark" effect="solid">
                                                            <p>Sensors and transmitters - the entire range
                                                                <br/> of temperature measuring devices is available with
                                                                SITRANS T. <br/>
                                                                Intrinsically safe and with globally recognized
                                                                certification.

                                                            </p>

                                                        </ReactTooltip>
                                                        <ReactTooltip id='global2' globalEventOff='click' place="top"
                                                                      type="dark" effect="solid">
                                                            <p>DURAG components - For emission monitoring
                                                                <br/> qualified <br/>
                                                                <ul>
                                                                    <li>dust/opacity sensors</li>
                                                                    <li>volume flow sensors</li>
                                                                </ul>

                                                            </p>

                                                        </ReactTooltip>


                                                        <img alt="symboleRecht" src={symboleRecht} width="95%"
                                                             style={{zIndex: -1}}/>
                                                    </div>

                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    <VerticalMenu/>

                </div>

            </>
        );

    }
}

const mapDispatchToProps = dispatch => {
    return  {
        changeTextBox1: (value) => dispatch({
            type: 'CHANGEBOXTEXT1' ,
            payload : value
        })
  }
}

const mapStateToprops = state => {
    return {
        boxt : state.boxText,
        displaytitl: state.displayTitle,
        displaybox : state.displayBox,
        cabconfig4: state.cabConfig4,
        changeTitle : state.boxTextTitle,
        schrankwidt : state.schrankWidth,
        dispaySchrankOpe: state.dispaySchrankOpen,
        displayButtonOpenCabine: state.displayButtonOpenCabinet,
        displayButtonCloseCabine: state.displayButtonCloseCabinet,
        isCabinetOpe: state.isCabinetOpen,
        marginLeftCabine: state.marginLeftCabinet,
        boxdispla:  state.boxDisplay


    }
}

export default connect( mapStateToprops,mapDispatchToProps)(PageContainer);