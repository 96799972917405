import React from 'react';
import logo from './media/Siemens.PNG';
import solidBiomass from './media/solid_biomass_fired_MCP_Icon.png';
import biomassFired from './media/biomass_fired_lpc_wei├ƒ.png';
import cementPlant from './media/cement_plant_wei├ƒ.png';
import coIncineration from './media/co-incineration_solids_wei├ƒ.png';
import coalFired from './media/coal_fired_lcp_wei├ƒ.png';
import naturalGas from './media/Natural_Gas_Wei├ƒ.png';
import {Link} from "react-router-dom";



const HomePage = () => {

    return (
     <>

         <div className="row no-gutter bg1" style={{height: "100%"}}>
             <div className="col-12 " style={{position: "relative",height: "100%",}}>

            <div className="row  my-3 justify-content-end mb-auto"  style={{width: "100%"}}>
                <div className="col-2 d-flex justify-content-end">
                    <img src={logo} alt="Logo" width="70%"/>
                </div>
            </div>
                 <div className="Homebox row no-gutter" >
                     <div className="col-4  text-center" style={{position:"relative", backgroundColor:"#449698",marginLeft:"5%"}} >

                        <p className="HomeBoxHeading" > Continuous and reliable <br/> emission monitoring </p>
                         <p className="HomeBoxText" >choose the right CEMS cabinet for your application - <br/>
                         made easy thanks to preconfigured components.
                         </p>

                     </div>

                 </div>

            <div className=" homePageMenu d-flex justify-content-center "  >
                <div   className=" mr-2 menuItem " style={{ maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/solidbiomass'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="  h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont"  >
                                <p>
                                    SOLID BIOMASS FIRED MCP
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={solidBiomass} alt="Logo" width="50" height="50"/>
                            </div>
                        </div>
                    </Link>

                </div>

                <div className=" mr-2 menuItem " style={{maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/naturalgas'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont" >
                                <p>
                                    NATURAL GAS FIRED TURBINE
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={naturalGas} alt="Logo" width="30" height="50"/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className=" mr-2  menuItem  " style={{position: "relative ",maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/coalfiredlcp'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont" >
                                <p>
                                    COAL FIRED LCP
                                </p>
                            </div>
                            <div className="d-flex justify-content-end" >
                                <img src={coalFired} alt="Logo" width="40" height="50"/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className=" mr-2 menuItem  " style={{position: "relative ",maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/biomassfiredlcp'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont" >
                                <p>
                                    BIOMASS FIRED LCP
                                </p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={biomassFired} alt="Logo" width="50" height="50"/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className=" mr-2 menuItem " style={{position:"relative",maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/coincinerationofsolids'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont" >
                                    CO-INCINERATION SOLIDS

                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={coIncineration} alt="Logo" width="40" height="50"/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className=" mr-2 menuItem " style={{position:"relative ",maxWidth:"12%",minWidth:"12%"}}>
                    <Link to={'/pagecontainer/cementplant'} style={{textDecoration: 'none', color: "white"}}>
                        <div className="h-100 d-flex flex-column mx-2">
                            <div className="mb-auto menuItemFont " >

                                    CEMENT PLANT

                            </div>
                            <div className="d-flex justify-content-end">
                                <img src={cementPlant} alt="Logo" width="60" height="40"/>
                            </div>
                        </div>
                    </Link>
                </div>


            </div>
             </div>
     </div>

         </>

    )
}

export default HomePage ;