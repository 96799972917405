import React,{Component} from 'react';
import {connect} from 'react-redux';



class ButtonCloseCabinet extends Component {

    render() {
        return (
        <div onClick={this.props.closeCabinet}  className=" text-white boxCloseCabinet  w-50 d-flex align-items-center justify-content-center "  >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x mr-1"
                 viewBox="0 0 16 16">
                <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
           Close

        </div>

        )
    }
}
const mapStateToprops = state => {
    return {


    }
}

const mapDispatchToProps = dispatch => {
    return  {
        closeCabinet: ( value) => dispatch({
            type: 'CLOSECABINET' ,


        })
    }
}

export default connect(mapStateToprops,mapDispatchToProps)( ButtonCloseCabinet);