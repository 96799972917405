import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_DURAG_1400TRX_675x267px.png";

const Durag = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                       Measures fully automatically and continuously
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="DURAG" src={UltramtBild}/>
                </div>


                <p>
                    The device not only records the total mercury concentration in mercury separators, but also reports and registers (also online) any violation of limits. As a result it is often possible to intervene directly in the process of the plant to be monitored and thus ensure reliable compliance with the specified limit values.
                </p>
                <h6>
                    Applications
                </h6>

                <ul>
                    <li>Sewage sludge and hazardous waste incineration</li>
                    <li>Limit monitoring in steel plants with scrap metal preparation</li>
                    <li>Contaminated soil burning plants (thermal cleanup of soil)</li>
                    <li> Contaminated soil burning plants (thermal cleanup of soil)</li>
                    <li>Fluorescent light bulb recycling</li>

                </ul>


                <h6>
                    Sectors
                </h6>


                <ul>
                    <li>Waste incineration plants</li>
                    <li>Coal-fired power plants</li>
                    <li>Crematoria</li>
                    <li>Mercury mines and refineries</li>
                    <li>Steel works</li>
                    <li>Recycling</li>

                </ul>


                <h6>Advantages</h6>

                <ul>
                    <li>Low maintenance dry reactor</li>
                    <li>High operational reliability</li>
                    <li>Easy maintenance, easy replacement of components</li>
                    <li>Low cross-sensitivities</li>
                    <li>Integrated calibration gas generator for automatic reference point control</li>
                    <li>Separate measurement of elemental and ionic mercury
                        as an option
                    </li>
                </ul>

            </div>

        </>
    )
}

export default Durag;