
import React from "react";
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import HomePage from "./HomePage";
import PageContainer from "./PageContainer";
import {data} from "./data";



function App() {
    const datas = data;
    return (
        <BrowserRouter>
            <div className="containerFluid text-white">
                <Switch>
                    <Route exact path="/" component={() => <HomePage/>}/>
                    <Route  path="/pagecontainer/:id" component={({match}) => <PageContainer match={match} data={datas}/>}/>
                </Switch>
            </div>
        </BrowserRouter>





    );
}

export default App;
