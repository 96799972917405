import React from "react";

const Text1Clement = () => {

    return(
        <div className="container">
            <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                <h5>
                    17th BImSchV - German regulations for IED<br/>
                    Annex VI applications
                </h5>
            </div>
            <h6>
                The German 17th BImSchV regulation for waste incineration and co-
                incinerators is also valid for cement plants and covers continuously to
                be monitored emission limit values for:
            </h6>

                <ul>
                    <li> Dust</li>
                    <li> Carbon monoxide(CO)</li>
                    <li> Oxides of nitrogen (NO<su>x</su>)</li>
                    <li> Sulphur dioxide (SO<sub>2</sub>) </li>
                    <li>Ammonia (NH <sub>3</sub>)</li>
                    <li> Hydrogen cloride (HCI)</li>
                    <li> Hydrogen Fluoride (HF)</li>
                    <li>Total organic carbon (TOC)</li>
                    <li> Mercury</li>
                </ul>


                <h6>
                    For referencing and cross-correlation purpose additional
                    measurement components commonly used:
                </h6>


                <ul>
                    <li> Oxygen (O<sub>2</sub>) </li>
                    <li> Moisture(H<sub>2</sub>O)</li>
                    <li>Volume flow</li>
                </ul>


                <h6>Complete AMS (CEMS) should  be QAL1 certified according to
                    EN14181 and EN15267 => TÜV/UBA approval
                </h6>



        </div>
    )
}

export default Text1Clement;