import React from "react";
import Gerät4 from "./media/Schaltschrank_4_Geraete.png";


const Schrank4Gerät = () => {
    return (
        <>

            <img src={Gerät4} alt="schrank"  width="135%" height="97%"/>


        </>
    )
}

export default React.memo(Schrank4Gerät);