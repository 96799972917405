import React from "react";

const Text1Biomass = () => {
    return(
        <div className="container">
            <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                <h5>
                    13th BLmSchV - German regulations for IED<br/>
                    Annex V applications
                </h5>
            </div>
            <h6>
                The German 13th BImSchV regulation for large combustion plants
                covers the continuous monitoring of emission limit values for levels of:
            </h6>

                <ul>
                    <li> Dust</li>
                    <li> Carbon monoxide (CO)</li>
                    <li> Oxides of nitrogen (NO<sub>x</sub>)</li>
                    <li> Sulphur dioxide (SO <sub>2</sub>) </li>
                </ul>


                <h6>
                    Plant-type specific also:
                </h6>


                <ul>
                    <li> Total organic carbon(TOC) </li>
                    <li> Ammonia (NH<sub>3</sub>)</li>
                    <li>Mercury</li>
                </ul>


           <h6> For referencing and cross-correlation purpose additional
            measurement components commonly used:</h6>
                <ul>
                    <li> Oxygen (O<sub>2</sub>)</li>
                    <li> Moisture (H<sub>2</sub>O)</li>
                    <li> Volume flow</li>
                </ul>


                <h6>
                    Complete AMS (CEMS) should be QAL1 certified according to EN14181
                    and EN15267 => TÜV/UBA approval
                </h6>



        </div>
    )
}

export default Text1Biomass;