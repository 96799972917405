import React from "react";

const Text2Clement = () => {

    return(
        <div className="container">
            <div  className="mb-2" style={{borderBottom:"solid white 1px"}}>
                <h5>
                    European IED-Industrial Directive 2010/75/EU, with Amex VI for waste incineration<br/>
                    and cement plants


                </h5>
            </div>

            <h6>
                The 2010/75/EU Industrial Emissions Directive, with Annex VI for waste
                incineration and cement plants  covers continuously
                monitored emission limit values in general for:
            </h6>

                <ul>
                    <li> Dust</li>
                    <li> Carbon monoxide(CO)</li>
                    <li> oxides of nitrogen (NO<sub>x</sub>)</li>
                    <li> Sulphur dioxide (SO<sub>2</sub>) </li>
                    <li>Ammonia (NH<sub>3</sub>)</li>
                    <li> Hydrogen cloride (HCL)</li>
                    <li> Hydrogen Fluoride (HF)</li>
                    <li>Total organic carbon (TOC)</li>
                    <li> Mercury</li>
                </ul>


                <h6>
                    For referencing and cross-correlation purposes additional
                    measurement components commonly used:
                </h6>


                <ul>
                    <li> Oxygen (O<sub>2</sub>) </li>
                    <li> Moisture (H<sub>2</sub>O)</li>
                    <li>Volume flow</li>
                </ul>


                <h6>Analyser within AMS (CEMS) should  be QAL1 certified according to
                    EN14181 => MCERTS or  TÜV/UBA approval according to EN15267
                </h6>



        </div>
    )
}

export default Text2Clement;