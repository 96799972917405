import React from "react";
import UltramtBild from "./media/Touch-App-Geraeteabbildung_LDS6_1_675x267px.png";

const Lds6 = () => {
    return(
        <>

            <div className="container mt-3">
                <div className="mb-2" style={{borderBottom: "solid white 1px"}}>
                    <h5>
                       A real pro for harsh conditions
                    </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <img alt="LDS6" src={UltramtBild}/>
                </div>


                <p>The LDS 6 functions according to the measuring principle of the specific light absorption of different gas components and is ideally suited for fast and non-contact measurement of gas concentrations in process or flue gases. The sensors are designed for operation under harsh environmental conditions and contain a minimum of electrical components. LDS 6 enables the measurement of one or two gas components at every of maximum three measuring points or the gas temperature directly in the process.
                </p>
                <h6>
                    Applications
                </h6>

                <ul>
                    <li>With high dust loads</li>
                    <li> In hot, humid, corrosive, explosive, or toxic gases</li>
                    <li>In applications showing strong varying gas compositions</li>
                    <li> Under harsh environmental conditions at the measuring point</li>
                    <li>Continuous emission monitoring for all kinds of
                        fuels (oil, gas, coal, and others)
                    </li>
                    <li>Measurements in corrosive and toxic gases</li>

                </ul>


                <h6>
                    Sectors
                </h6>


                <ul>
                    <li>Power plants</li>
                    <li>Steel works</li>
                    <li>Cement industry</li>
                    <li>Chemical and petrochemical plants</li>
                    <li>Automotive industry</li>
                    <li>Waste incineration plants</li>
                    <li>Glass and ceramics production</li>
                    <li>Research and development</li>
                    <li>Semiconductor and computer chip production</li>
                </ul>


                <h6>Advantages</h6>

                <ul>
                    <li>Process optimization thanks to high availability and unique analytical selectivity</li>
                    <li>Process control</li>
                    <li>Explosion protection</li>
                    <li>Highly selective, i.e. mostly without cross-sensitivities</li>
                    <li>Quality control</li>
                    <li>Environmental protection</li>
                    <li>Plant and operator safety</li>
                </ul>

            </div>

        </>
    )
}

export default Lds6;